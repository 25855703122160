import { postFakeLogin } from "helpers/fakebackend_helper";
import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import callApi from "Common/utils/apiCaller";

interface User {
  email: string;
  password: string;
}

export const loginUser =
  (
    user: User,
    history: any
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      let res = await callApi("admin/login", "post", {
        admin: { email: user.email, password: user.password },
      });

      if (res.success) {
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...res.data.admin,
            token: res.data.token,
          })
        );
        dispatch(loginSuccess(res.data.admin));
        history("/");
      }
    } catch (error) {
      dispatch(loginError(error));
    }
  };

export const logoutUser = () => async (dispatch: Dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutSuccess(true));
  } catch (error) {
    dispatch(loginError(error));
  }
};
