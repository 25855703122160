import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "Common/TableContainer";
import callApi from "Common/utils/apiCaller";

const Analytics = () => {
  const [data, setData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 1000;

  useEffect(() => {
    fetchData();
  }, [pageNum]);

  const fetchData = async () => {
    let res = await callApi(`leads?pageNum=${pageNum}&pageSize=${pageSize}`, "get", {});

    if (res.success) {
      setData(res.data.leads);
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Phone No",
        accessorKey: "phoneNumber",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "City",
        accessorKey: "city",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "State",
        accessorKey: "state",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "PinCode",
        accessorKey: "pinCode",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Services",
        accessorKey: "services",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <>
            <div>
              {cell.row.original.services.map(e => (<div>{e.title} - {e.category}</div>))}
            </div>
          </>
        )
      },
      {
        header: "Pickup Date",
        accessorKey: "pickupDate",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Pickup Time",
        accessorKey: "pickupTime",
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Leads" pageTitle="Leads" />
      <div className="grid grid-cols-12 gap-x-5">
        <div className="col-span-12 card 2xl:col-span-12">
          <div className="card-body">
            <TableContainer
              isPagination={true}
              columns={columns || []}
              data={data || []}
              customPageSize={50}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
