import axios from "axios";

// const API_URL = `http://localhost:8080`;
const API_URL =
  process.env.REACT_APP_API_URL || `https://www.onlinelaundrywala.com`;

export default async function callApi(
  endpoint: string,
  method: "post" | "get" = "post",
  body: any
) {
  let authUser = window.localStorage.getItem("authUser")
    ? window.localStorage.getItem("authUser")
    : null;

  let headers = {
    "content-type": "application/json",
  };

  console.log(authUser);
  console.log(typeof authUser);
  if (authUser) {
    let user = JSON.parse(authUser);
    let token = user.token;
    if (token && token !== "") {
      // @ts-ignore
      headers.Authorization = `Bearer ${token}`;
    }
  }

  return (await axios({
    url: `https://${API_URL}/api/${endpoint}`,
    method,
    data: body,
    headers,
  })) as any;
}
